/*
CONTENT BLOCK mit zwei Spalten Bildern 
*/

import * as React from "react";

// CSS
import "../teaser/teaser.css";
import { isMobile } from "react-device-detect";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const ProductInfoDataZwei = ({
  image1,
  image2,
  unterschrift1,
  unterschrift2,
  headline,
  text,
  html,
  children,
}) => {
  return (
    // CONTAINER
    <div className="produkt_content_container">

      {/* Content // Add twi images */}
      <div className="spalten">

        {/* Links */}
        <div className="spaltelinks">
          <img
            className="img_content "
            src={image1}
            alt={"Content"}
          />
         <span className="bildunterschrift" dangerouslySetInnerHTML={{__html: unterschrift1}}/>
        </div>

        {/* Rechts*/}
        <div className="spalterechts">
          <img
            className="img_content"
            src={image2}
            alt={"Content"}
          />
          <span className="bildunterschrift" dangerouslySetInnerHTML={{__html: unterschrift2}}/>
          
        </div>
      </div>

      <div className="text">
        <div>
          <p className="bild-headline">{headline}</p>
         
          {
             html? 
             <p className="bild-text" dangerouslySetInnerHTML={{__html: html}}/>
             :
             <p className="bild-text">{text}</p>
           } 
           
          {children}
        </div>
      </div>
    </div>
  );
};
export default ProductInfoDataZwei;
